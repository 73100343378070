
import { defineComponent } from 'vue'
import BulkInstructionAutocomplete from '@/components/TableComponents/Form/BulkCommon/BulkInstructionAutocomplete.vue'
import TooltipBulkInstructionAutocomplete
  from '@/components/TableComponents/Form/BulkCommon/TooltipBulkInstructionAutocomplete.vue'

export default defineComponent({
  name: 'FullBulkInstructionAutocomplete',
  components: { TooltipBulkInstructionAutocomplete, BulkInstructionAutocomplete },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    dsp: {
      type: String,
      required: true
    }
  },
  methods: {
    addIoIdToBulk (ioId: string[]) {
      this.$emit('add-io-id-to-bulk', ioId)
    }
  }
})
